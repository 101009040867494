<template>
  <div class="not-found-container">
    <page-not-found v-bind:pageFountOrNot="pageFountOrNot"></page-not-found>
    <div v-if="pageFountOrNot">
      <p class="error-message">回家喽 ^_^</p>
      </div>
    <div v-else>
      <p class="error-message">孩子找不到路了 😭😭😭</p>
    <el-button type="success" @click="redirectToHome" round>点我送孩子回家</el-button>
    </div>


  </div>
</template>

<script>
import PageNotFound from "@/components/pageNotFound";
export default {
  name: "NotFound",
  data() {
    return {
    pageFountOrNot: false,
  }
  },
  components: {PageNotFound},
  methods: {
    redirectToHome() {
         // 设置一个延迟时间，单位为毫秒（这里设置为1000毫秒，即1秒）
    const delayInMilliseconds = 1000;
    this.pageFountOrNot = true;
    // 将 this 赋值给变量，以在 setTimeout 中访问
    const vm = this;

    // 使用 setTimeout 函数延迟执行路由跳转
    setTimeout(function() {
      // 在延迟结束后执行路由跳转
      vm.$router.push('/home');
    }, delayInMilliseconds);
    }
  }
};
</script>

<style scoped>
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa; /* Light background color */
}

.error-message {
  font-size: 24px;
  font-weight: bold;
  color: #dc3545; /* Red color for error message */
  margin-bottom: 30px;
}

.el-button {
  background-color: #28a745; /* Green color for button */
  color: #fff; /* White text color */
  border-color: #28a745; /* Green border color */
  font-weight: bold;
  padding: 12px 20px;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.el-button:hover {
  background-color: #218838; /* Darker green on hover */
}


</style>
