<template>
<div >
<svg v-if="pageFountOrNot" t="1712631280928" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10901" width="200" height="200"><path d="M651.6 495.7c19.9 0 36.1-16.2 36.1-36.1v-68.7c0-19.9-16.2-36.1-36.1-36.1-19.9 0-36.1 16.2-36.1 36.1v68.7c0 19.9 16.2 36.1 36.1 36.1zM372.7 495.7c19.9 0.2 36.1-16 36.1-36.1v-68.7c0-19.9-16.2-36.1-36.1-36.1-19.9 0-36.1 16.2-36.1 36.1v68.7c0 19.9 16.2 36.1 36.1 36.1z" p-id="10902" fill="#1296db"></path><path d="M678.8 97.8c-54.5-23-111-33.8-166.6-33.8-167.5 0-326.8 98.7-396 262.9C31.1 529 112.3 759.7 298 866.1c2.2 1.3 6.4 5.1 3.6 10.8-8.2 15.5-34.2 52.5-96.9 58.8-4.2 0.5 0.2 4.6 6.4 6.5 23.9 7.3 72.1 17.9 128.8 17.9 49.8 0 106.2-8.2 158.6-34 4.4-2.3 8.5-3 10.8-3h2.5c167.5 0 326.8-98.6 396-262.7 92.1-218.7-10.4-470.5-229-562.6z m187 545.1c-60 142.5-198.9 234.7-353.8 234.7h-2.5c-10.1 0-21.2 2.7-31.1 7.6-39.6 19.5-86.1 29.3-138.5 29.3-2.6 0-5.2-0.1-7.7-0.1 4.4-6.2 7.6-11.7 9.9-16l0.3-0.6 0.2-0.6c12.7-25.9 3.4-56.3-21.9-70.9-167.7-96-237.6-303.2-162.3-481.6C218.5 202 357.3 109.8 512.2 109.8c51.1 0 101.2 10.2 148.9 30.5 195 82.1 286.9 307.6 204.7 502.6z" p-id="10903" fill="#1296db"></path><path d="M513 714.6c-72.6 0-139.5-40.6-172-100.4h-28.3c33.5 72.5 112.5 125 200.3 125 87.7 0 166.8-52.5 200.3-125h-28.2C652.6 674 585.6 714.6 513 714.6z" p-id="10904" fill="#1296db"></path></svg>
<svg v-else width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
  <!-- 定义一个透视效果的滤镜 -->
  <defs>
    <filter id="perspective">
      <!-- 定义透视效果 -->
      <fePerspective value="100" />
      <!-- 定义阴影 -->
      <feDropShadow dx="0" dy="2" stdDeviation="2" flood-color="rgba(0,0,0,0.3)" />
    </filter>
  </defs>

  <!-- 404错误的脸部，应用透视效果 -->
  <circle cx="100" cy="100" r="80" fill="#f7cac9" stroke="#333" stroke-width="2" filter="url(#perspective)" />

  <!-- 眼睛 -->
  <circle cx="70" cy="80" r="10" fill="#333" filter="url(#perspective)" />
  <circle cx="130" cy="80" r="10" fill="#333" filter="url(#perspective)" />

  <!-- 嘴巴 -->
  <path d="M70,150 Q100,120 130,150" fill="none" stroke="#333" stroke-width="5" filter="url(#perspective)" />

  <!-- 旋转的404数字，应用透视效果 -->
  <g transform="rotate(-90 100 100)" filter="url(#perspective)">
    <text id="number" x="100" y="20" font-family="Arial, sans-serif" font-size="24" fill="#333">404</text>
    <!-- 添加旋转动画 -->
    <animateTransform xlink:href="#number"
                      attributeName="transform"
                      attributeType="XML"
                      type="rotate"
                      from="0 100 100"
                      to="360 100 100"
                      dur="3s"
                      repeatCount="indefinite"/>
  </g>
</svg>
</div>


</template>

<script>
export default {
name: "pageNotFound",
  props: {
     'pageFountOrNot': Boolean
    },
}
</script>

<style scoped>

</style>